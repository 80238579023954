import React from "react";
import Layout from "../components/layout";
import {Row, Container, Button} from 'react-bootstrap';
import globalStyle from "../styles/global.module.css";

const SuccessfulPaymentPage = ({}) => {
  return(
    <Layout>
        <Container>
          <Row className="justify-content-center row">
            <i className="fas fa-check-circle" style={{"fontSize": "4rem", "color": "#17AC82", "marginTop": "-4rem"}}></i>
          </Row>
          <Row className="justify-content-center row" style={{"marginTop": "2rem"}}>
            <h4>Your payment was successfully registered</h4>
          </Row>
          <Row className="justify-content-center row" style={{"marginBottom": "3rem"}}>
            <span>Thank you for your payment</span>
          </Row>

          <Row className="justify-content-center row" style={{"marginBottom": "8rem"}}>
            <Button className={[globalStyle.btnPrimary, globalStyle.redirectButtonPink]}  href="/shop/clothes/" role="button">Continue shopping</Button>
          </Row>
        </Container>
    </Layout>
  )
};

export default SuccessfulPaymentPage;
